'use client';

import { Toaster as Sonner } from 'sonner';
import { cn } from '~/utils/cn';
import { headingVariants, textVariants } from './Typography';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      theme="light"
      className="toaster group"
      icons={{
        info: (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40" height="40" rx="20" fill="#E6EDD1" />
            <mask
              id="mask0_988_20313"
              maskUnits="userSpaceOnUse"
              x="8"
              y="8"
              width="24"
              height="24"
            >
              <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_988_20313)">
              <path
                d="M17.5506 25.5754C17.4173 25.5754 17.2923 25.5544 17.1756 25.5124C17.0589 25.4711 16.9506 25.4004 16.8506 25.3004L12.5506 21.0004C12.3673 20.8171 12.2796 20.5794 12.2876 20.2874C12.2963 19.9961 12.3923 19.7587 12.5756 19.5754C12.7589 19.3921 12.9923 19.3004 13.2756 19.3004C13.5589 19.3004 13.7923 19.3921 13.9756 19.5754L17.5506 23.1504L26.0256 14.6754C26.2089 14.4921 26.4466 14.4004 26.7386 14.4004C27.0299 14.4004 27.2673 14.4921 27.4506 14.6754C27.6339 14.8587 27.7256 15.0961 27.7256 15.3874C27.7256 15.6794 27.6339 15.9171 27.4506 16.1004L18.2506 25.3004C18.1506 25.4004 18.0423 25.4711 17.9256 25.5124C17.8089 25.5544 17.6839 25.5754 17.5506 25.5754Z"
                fill="#5E9E8B"
              />
            </g>
          </svg>
        ),
        success: (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40" height="40" rx="20" fill="#E6EDD1" />
            <mask
              id="mask0_988_20313"
              maskUnits="userSpaceOnUse"
              x="8"
              y="8"
              width="24"
              height="24"
            >
              <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_988_20313)">
              <path
                d="M17.5506 25.5754C17.4173 25.5754 17.2923 25.5544 17.1756 25.5124C17.0589 25.4711 16.9506 25.4004 16.8506 25.3004L12.5506 21.0004C12.3673 20.8171 12.2796 20.5794 12.2876 20.2874C12.2963 19.9961 12.3923 19.7587 12.5756 19.5754C12.7589 19.3921 12.9923 19.3004 13.2756 19.3004C13.5589 19.3004 13.7923 19.3921 13.9756 19.5754L17.5506 23.1504L26.0256 14.6754C26.2089 14.4921 26.4466 14.4004 26.7386 14.4004C27.0299 14.4004 27.2673 14.4921 27.4506 14.6754C27.6339 14.8587 27.7256 15.0961 27.7256 15.3874C27.7256 15.6794 27.6339 15.9171 27.4506 16.1004L18.2506 25.3004C18.1506 25.4004 18.0423 25.4711 17.9256 25.5124C17.8089 25.5544 17.6839 25.5754 17.5506 25.5754Z"
                fill="#5E9E8B"
              />
            </g>
          </svg>
        ),
        error: (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40" height="40" rx="20" fill="#CB482B" />
            <mask
              id="mask0_846_35086"
              maskUnits="userSpaceOnUse"
              x="8"
              y="8"
              width="24"
              height="24"
            >
              <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_846_35086)">
              <path
                d="M20.0008 21.4008L15.1008 26.3008C14.9174 26.4841 14.6841 26.5758 14.4008 26.5758C14.1174 26.5758 13.8841 26.4841 13.7008 26.3008C13.5174 26.1174 13.4258 25.8841 13.4258 25.6008C13.4258 25.3174 13.5174 25.0841 13.7008 24.9008L18.6008 20.0008L13.7008 15.1008C13.5174 14.9174 13.4258 14.6841 13.4258 14.4008C13.4258 14.1174 13.5174 13.8841 13.7008 13.7008C13.8841 13.5174 14.1174 13.4258 14.4008 13.4258C14.6841 13.4258 14.9174 13.5174 15.1008 13.7008L20.0008 18.6008L24.9008 13.7008C25.0841 13.5174 25.3174 13.4258 25.6008 13.4258C25.8841 13.4258 26.1174 13.5174 26.3008 13.7008C26.4841 13.8841 26.5758 14.1174 26.5758 14.4008C26.5758 14.6841 26.4841 14.9174 26.3008 15.1008L21.4008 20.0008L26.3008 24.9008C26.4841 25.0841 26.5758 25.3174 26.5758 25.6008C26.5758 25.8841 26.4841 26.1174 26.3008 26.3008C26.1174 26.4841 25.8841 26.5758 25.6008 26.5758C25.3174 26.5758 25.0841 26.4841 24.9008 26.3008L20.0008 21.4008Z"
                fill="#FFFDFA"
              />
            </g>
          </svg>
        ),
      }}
      toastOptions={{
        classNames: {
          toast:
            'text-subdued-foreground space-x-2 rounded-2xl max-w-[400px] w-full group toast group-[.toaster]:bg-surface-on group-[.toaster]:text-foreground group-[.toaster]:border-none group-[.toaster]:shadow-lg',
          description: cn(
            'group-[.toast]:text-subdued-foreground font-medium ',
            textVariants({ size: 'm' }),
          ),
          actionButton: 'group-[.toast]:bg-primary group-[.toast]:text-on',
          cancelButton: 'group-[.toast]:bg-muted group-[.toast]:text-subdued-foreground',
          title: headingVariants({ size: 's', class: 'line-clamp-2' }),
          icon: 'mb-auto size-10 [&_svg]:size-10 aspect-square',
        },
      }}
      {...props}
    />
  );
};

export { Toaster };
